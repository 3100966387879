.pastEvent {
  background-image: url("./assets/images/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: 50px 0;
}

.leftColumn {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.pastEventImg {
  width: 80%;
}

.rightColumn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 678px) {
  .pastEvent {
    display: block;
    padding: 20px 0;
  }
  .leftColumn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .pastEventImg {
    width: 100%;
  }

  .rightColumn {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 50px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
