.whatsappButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsappButton:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.whatsappIcon {
  width: 30px;
  height: 30px;
}
