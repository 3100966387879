.cont {
  display: grid;
  place-items: center;
  min-height: 922px;
  -webkit-mask-box-image: -webkit-linear-gradient(
    top,
    transparent,
    transparent 0,
    white 0,
    white 80%,
    transparent 100%
  );
}

.cards {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cards .card {
  position: absolute;
  display: grid;
  grid-template-columns: 70px 1fr;

  gap: 10px;
  padding: 1.5rem;
  padding-top: 6rem;
  opacity: 0;
  background: #f67f00;
  width: 80rem;
  height: 478px;
  border-radius: 2rem;
  pointer-events: none;
  animation: slideUp 15s infinite;
}

@media (max-width: 678px) {
  .cards .card {
    width: 20rem;
    padding-top: 4rem;
    padding-left: -10px;
  }
}

.cards .card:nth-child(1) {
  animation-delay: -6s;
}

.cards .card:nth-child(2) {
  animation-delay: -3s;
}

.cards .card:nth-child(3) {
  animation-delay: 0s;
}

.cards .card:nth-child(4) {
  animation-delay: 3s;
}

.cards .card:nth-child(5) {
  animation-delay: 6s;
}

.cards .card:nth-child(6) {
  animation-delay: 9s;
}

.cards .card:nth-child(7) {
  animation-delay: 12s;
}

.cards .card:nth-child(8) {
  animation-delay: 15s;
}

.cards .card:nth-child(9) {
  animation-delay: 18s;
}

.cards .card:nth-child(10) {
  animation-delay: 21s;
}

.cards .card:nth-child(11) {
  animation-delay: 24s;
}

.cards .card:nth-child(12) {
  animation-delay: 27s;
}

.cards .card:nth-child(13) {
  animation-delay: 30s;
  /* Repeat from 1 after the 10th card */
}

.cards .card:nth-child(14) {
  animation-delay: 33s;
  /* Repeat from 2 after the 11th card */
}

@keyframes slideUp {
  0% {
    transform: translateY(-70%) scale(0.5);
    opacity: 0;
    z-index: -1;
  }

  5%,
  20% {
    transform: translateY(-50%) scale(0.6);
    opacity: 0.3;
    z-index: 1;
  }

  25%,
  40% {
    transform: translateY(-30%) scale(0.7);
    opacity: 0.8;
    z-index: 2;
  }

  45%,
  60% {
    transform: translateY(0%) scale(1);
    opacity: 1;
    z-index: 3;
  }

  65%,
  100% {
    transform: translateY(100%) scale(0.8);
    opacity: 0;
    z-index: -1;
  }
}

.card .rating {
  width: 100%;
  text-align: center;
}

.left,
.right {
  width: 1.5rem;
  height: 1rem;
}

@media (min-width: 1024px) {
  .left,
  .right {
    width: 4rem;
    height: 3rem;
  }
}
