/* Container for the slideshow */
.slideshow-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 34px;
    overflow: hidden; /* Ensure no overflow outside the container */
  }
  
  /* Image container for the slideshow */
  .slideshow-images {
    display: flex;
    width: 100%;
    height: 100%; /* Set height to 100% to match the container height */
    transition: transform 0.5s ease-in-out;
  }
  
  /* Default slideshow image styling */
  .slideshow-image {
    flex-shrink: 0;
    width: 100%; /* This will stretch images to fill the container */
    height: 100%; /* Height will now adapt to the container size */
    object-fit: cover; /* Maintain aspect ratio while covering the area */
    border-radius: 0; /* Remove any border radius */
  }
  
  /* Responsive adjustments */
  
  /* For medium-sized screens below 768px */
  @media (max-width: 768px) {
    .slideshow-image {
      height: 100%; /* Maintain the height based on the container size */
    }
  }
  
  /* For small screens below 640px */
  @media (max-width: 640px) {
    .slideshow-image {
      height: 100%; /* Maintain the height based on the container size */
    }
  }
  